@font-face {
  font-family: "Patrick Hand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../public/PatrickHand-Regular.ttf") format("woff2");
}

a, a:visited {
  color: #450A42;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}